<template>

    <nav>
      <div class="logo">
        <img src="https://images.evetech.net/alliances/99010735/logo" alt="SL0W Logo">
        <span>SL0W CHILDREN AT PLAY</span>
      </div>
  
      <router-link to="/">Entry</router-link>
      <router-link to="/uni">University</router-link>
      <router-link to="/main">Main Alliance</router-link>

      <div class="bg">
        <div class="striped" />
        <div class="dark-blur" />
      </div>
    </nav>
  
  </template>

<style lang="scss">
@mixin tablet {
  @media only screen and (width < 980px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}


nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  position: fixed;
  top: 0;
  inset-inline: 0;
  z-index: 100;

  padding-inline: 2rem;
  @include mobile {padding-inline: 1rem;}

  height: var(--nav-height);
  width: 100%;
  font-family: var(--nav_font, --text_font, Arial, sans-serif);

  // transparent nav
  background: #0006;
  backdrop-filter: blur(30px);
  // solid nav
  background: var(--content_dark);

  a {
    position: relative;
    font-weight: bold;
    color: var(--font_color);
    text-decoration: none;
    transition: color 350ms ease-in-out;

    &::after {
      content: ' ';
      position: absolute;
      left: 0;
      bottom: -0.35rem; 
      height: 0.1rem;
      width: 100%;
      background: var(--color_lite);
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 250ms ease,
                  background 350ms ease-in-out;

    }
    &:visited {color: var(--font_color);}
    &:hover {
      color: var(--color_base);
      &::after {
        background: var(--color_base);
        transform: scaleX(1);
      }
    }

    &.router-link-exact-active {
      color: var(--color_lite);
      &::after {
        transform: scaleX(1);
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    gap: 1rem;

    margin-inline-end: auto;
    font-size: larger;
    font-weight: 600;

    img {
      height: calc(var(--nav-height) / 2 );
      @include mobile {
        height: calc(var(--nav-height) - 0.5rem );
      }
    }
    span {
      @include mobile {display: none; visibility: hidden;}
    }
  }

  .bg {
    position: absolute;
    top: 0; left: 0;
    z-index: -1;
    // isolation: isolate;
    
    height: var(--nav-height);
    width: calc(100% + 5rem);
  }
  .striped {
    --_stripeangle: -45deg;
    --_stripe-color: var(--color_logo);
    --_stripe-color: var(--color_darker);

    position: absolute; inset: 0;
    height: 100%;
    width: 100%;
      background: repeating-linear-gradient(
                  var(--_stripeangle),
                  var(--_stripe-color), var(--_stripe-color) 1.5rem,
                  transparent 1.5rem,
                  transparent 3.5rem,
                  var(--_stripe-color) 3rem
                  );
    // opacity: .1;
      // animation: marquee 1000ms linear forwards infinite;
      
    }

}



</style>