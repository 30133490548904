<template>

  <Navigation />

  <router-view :contents="content" />
  
  <LoadingScreen v-show="loadingStatus" />

</template>

<script>
import { inject } from 'vue';
import LoadingScreen from '@/components/TheLoadingScreen.vue';
import Navigation from '@/components/TheNavigation.vue';

export default {
  setup() {
    const loadingStatus = inject('loadingStatus');

    return {
      loadingStatus,
    }
  },
  components: {
    LoadingScreen, Navigation
  },
  data() {
    return {
      content: [
        {
            title: 'SL0W University',
            intro: 'some short sentence getting the reader hooked and willing to click the button to read more',
            blurp: 'some short description getting the reader hooked and willing to click the button to read more',
            button: {
                text: 'learn more',
                alt: 'tis a button',
                link: 'uni'
            },
            img: {
                src: 'dock.webp',
                alt: 'image'
            },
            arrow: '<<'
        }, {
            title: 'Main Alliance',
            intro: 'some short sentence getting the reader hooked and willing to click the button to read more',
            blurp: 'more short description getting the reader hooked and willing to click the button to read more',
            button: {
                text: 'enter',
                alt: 'tis a button',
                link: 'main'
            },
            img: {
                src: 'bash.webp',
                alt: 'image'
            },
            arrow: '>>'
        }
    ]
    }
  },
  methods: {
    imgSource(raw) {
      try {
          return require(`${raw}`)
      } catch (card) {
        try {
          return require(`@/assets/background/${raw}`)
          } catch (icon) {
              return require(`@/assets/icon/${raw}`);
          }
      }
    },
  },
}
</script>

<style lang="scss">
@mixin tablet {
  @media only screen and (width < 980px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}

@keyframes picturezoom {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(2);
    }  
}

:root {
  --header_font: 'Nunito Sans', sans-serif;
  --text_font: 'Rubik', sans-serif;
  --font_color_inverse: #1a1d20;
  --font_color: #eae4dc;

  --color_darker: #430;
  --color_dark: #842;
  --color_bold: #b50;
  --color_base: #e80;
  --color_lite: #fb4;
  --color_logo: #ffe900;

  --content_dark: #141618;
  --content_bold: #1a1d20;
  --content_boldglass: #1a1d20ee;
  --content_base: #23292d;
  --content_baseglass: #23292ddd;
  --content_mid: #31383d;
  --content_lite: #3b444a;

  --nav-height: 4rem;
  
}
*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    /* FIREFOX scrollbar */
    scrollbar-width: thin;
    scrollbar-color: var(--content_mid) var(--content_bold); /*thumb | track*/
}
/* other scrollbars */
*::-webkit-scrollbar{
    height: .5rem; width: .5rem;
}
*::-webkit-scrollbar-track {
    width: 0; height: 0;
    background-color: var(--content_bold);
    // border-radius: .5rem;
}
*::-webkit-scrollbar-thumb {
    background: var(--content_mid);
    // border-radius: .5rem;
}


#app {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  height: 100vh;

  font-family: var(--text_font, Arial, sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--font_color);
}


// PAGE
.page {
  overflow-y: auto;
  height: 100vh; width: 100vw;

}

// CONTENT
.content {
  

  section {
    display: grid;
    position: relative;
    background: var(--content_base);

    --_section-offset-block: calc(var(--nav-height) * 1.5);
    --_section-offset-inline: max(1rem, 15vw);
    @include mobile {
      --_section-offset-block: var(--nav-height);
      --_section-offset-inline: max(1rem, 5vw);
    }

    &.default {
      gap: 1.5rem;

      padding-block: var(--_section-offset-block);
      padding-inline: var(--_section-offset-inline);
    }

    &.gap {
      background: transparent;
      img {
        position: fixed;
        inset: 0;
        object-fit: cover;
        object-position: center;
        z-index: -1;
        isolation: isolate;
      }
    }

    &.infogrid {
      gap: 1.5rem;

      padding-block: var(--_section-offset-block);
      padding-inline: var(--_section-offset-inline);

      grid-template-columns: 1fr 1fr 1fr;
      

    .grid {
      &__title {
        grid-column: span 3;
        }
      }
    }


    &.side-media {
      display: flex;
      background: var(--content_lite);
      padding-block-start: 0;
      
      .media {
        &__text {
          padding-block: var(--_section-offset-block);
          padding-inline: calc(var(--_section-offset-inline) / 2 );

          flex-basis: 50%;
          display: flex;
          flex-flow: column wrap;
          gap: 1.5rem;
        }
        &__image {
          position: relative;
          flex-basis: 50%;

          img {
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      &.reverse {
        flex-direction: row-reverse;
      }
    }

    &.footer {
      background: var(--content_bold);

      gap: 1.5rem;

      padding-block-start: calc(var(--_section-offset-block) / 4 );
      padding-inline: calc(var(--_section-offset-inline) / 2 );

      grid-template-columns: 1fr 1fr;
      
      ul {
        list-style-type: none;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
      }

      .grid {
        &__title {
          grid-column: span 1;
          justify-self: start;
          }
        &__socials {
          grid-column: 2;
          img {
            height: 2rem;
            width: 2rem;
          }
        }
        }
    
    }
  }

}

// HEADERS
.title {
  font-family: var(--header_font);
  font-weight: 1000;
  text-transform: uppercase;
  letter-spacing: .1rem;
}
h1 {font-size: x-large; margin-block-end: 2rem;}
h2 {font-size: larger;}

// LINK PRESET
a {
    color: var(--color_bold);
    transition: color 350ms ease-in-out;

    &:visited { color: var(--color_dark); }
    &:hover { color: var(--color_base); }
    &:focus-visible { 
      outline: none;
      color: var(--color_base);
      text-decoration: underline;
    }
  }

// BUTTON: default no class, round class
.button {
    position: relative;
    border: 2px solid var(--color_base);
    background: #0001;
    backdrop-filter: blur(30px);
    overflow: hidden;
    isolation: isolate;

    font-size: large;
    font-weight: 600;
    text-transform: capitalize;

    &.round {
        border-radius: 2rem;
    }
    &.text-button {
      color: var(--font_color);
      padding: .5rem 2rem;

      transition: color 350ms ease-in-out;

      &:hover { color: var(--font_color_inverse); }
      &:focus-visible { 
        outline: none;
        color: var(--font_color_inverse);
        text-decoration: underline;
      }
    }
    a {
        display: block;
        position: relative; inset: 0;
        z-index: 15;

        font-size: large;
        font-weight: 600;
        text-transform: capitalize;
        color: var(--font_color);
        text-decoration: none;

        min-width: 10rem;
        padding: .5rem 2rem;

        &:visited { color: var(--font_color); }
        &:hover { color: var(--font_color_inverse); }
        &:focus-visible { 
          outline: none;
          color: var(--font_color_inverse);
          text-decoration: underline;
        }
    }
    

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        
        background: var(--color_base);

        transform: scaleX(0) skewX(-90deg);
        transform-origin: right;
        transition: transform 250ms ease-in-out;
    }
    &:hover::after,
    &:focus-within::after {
        transform: scaleX(1) skewX(-45deg);
        transform-origin: left;
    }

    &.round::after {
        border-radius: 2rem;
        transform: scaleX(0);
    }
    &.round:hover::after,
    &.round:focus-within::after {
        transform: scaleX(1);
        transform-origin: left;
    }
}

</style>
