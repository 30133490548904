import { createRouter, createWebHistory } from 'vue-router'
import { inject } from 'vue';

import LandingView from '../views/LandingView.vue'

const routes = [
  {
    path: '/',
    name: 'entry',
    component: LandingView,
    meta: {
      title: 'Welcome!'
    }
  },
  {
    path: '/uni',
    name: 'uni',
    component: () => import(/* webpackChunkName: "uni" */ '../views/UniView.vue'),
    meta: {
      title: 'University'
    }
  },
  {
    path: '/main',
    name: 'main',
    component: () => import(/* webpackChunkName: "main" */ '../views/MainAllianceView.vue'),
    meta: {
      title: 'Alliance'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from) => {
//   document.title = 'SL0W CHILDREN AT PLAY  -  ' + to.meta.title || 'SL0W CHILDREN AT PLAY';
// })


router.beforeEach((to, from, next) => {
  document.title = 'SL0W CHILDREN AT PLAY  -  ' + to.meta.title || 'SL0W CHILDREN AT PLAY';

  const setLoadingStatus = inject('setLoadingStatus');
  setLoadingStatus(true);
  setTimeout(() => {
    setLoadingStatus(false);
  }, 800);
  next();
});

export default router
