<template>
    <section class="default infogrid footer">
        <div class="grid__title title">Footer Title</div>
        <div class="grid__socials">
            <ul>
                <li><a><img src="" alt=""></a></li>
                <li><a><img src="" alt=""></a></li>
                <li><a><img src="" alt=""></a></li>
                <li><a><img src="" alt=""></a></li>
            </ul>
        </div>

    </section>
</template>
