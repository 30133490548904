<template>
    <section class="hero">
            <div class="hero--text">
                <h1 class="title"> {{ title }}</h1>
                <p>
                    {{ heroBlurp }}
                </p>
                <button class="button text-button">{{ buttonText }}</button>
            </div>
            <div class="hero--spacer" />
            <div class="hero--image screen" />
            <img class="hero--image" :src="imgSource(rawImageSrc)" alt="hero section background">
        </section>
</template>

<script>
export default {
    name: 'HeroSection',
    props: {
        title: String,
        heroBlurp: String,
        rawImageSrc: String,
        buttonText: String
    },
    computed: {

    },
    data() {
        return {
            
        }
    },
    methods: {
        imgSource: function (rawImageSrc) {
            return this.$root.imgSource(rawImageSrc)
        }
    }
}
</script>

<style lang="scss">
@mixin tablet {
  @media only screen and (width < 980px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}



.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  isolation: isolate;
  height: 75vh;
  overflow: hidden;
  background: var(--content_base);

  
  transition: opacity 350ms ease-in-out;

  @include tablet {
    grid-template-columns: 1fr;
  }

  &--text {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;
    margin-inline-start: 10vw;

    @include tablet {
      grid-column: span 2;
      min-height: 75vh;
      margin-inline: 10vw;
    }

    .title {
      margin-block: 0;
      text-align: initial;
      font-size: xx-large;
    }
    p {margin-block: 0; text-align: start;}
  }

  &--spacer {
    @include tablet {
      display: none;
      visibility: hidden;
    }
  }

  &--image {
    position: absolute;
    inset: 0;
    height: 75vh;
    width: 100vw;
    object-position: center;
    object-fit: cover;

    animation: picturezoom 1500ms reverse cubic-bezier(0.97, -0.02, 0.65, 1.02);

    &.screen {
      background: var(--content_boldglass);
      mix-blend-mode: multiply;
      opacity: .55;
      z-index: 1;
      transition: opacity 350ms ease-in-out;
    }
  }
  &:hover .screen {
    opacity: .95;
  }

}
</style>