// import { createApp, ref } from 'vue';
// import App from './App.vue'
// import router from './router'

// createApp(App).use(router).mount('#app')


import { createApp, provide, ref } from 'vue';
import App from './App.vue';
import router from './router';

const app = createApp(App);

const loadingStatus = ref(true);

const setLoadingStatus = (value) => {
  loadingStatus.value = value;
};

app.provide('loadingStatus', loadingStatus);
app.provide('setLoadingStatus', setLoadingStatus);

app.use(router).mount('#app');
