<template>
    <div class="page uni">

        <TheHeroSection
            :title="contents[0].title"
            :hero-blurp="contents[0].blurp"
            :raw-image-src="contents[0].img.src"
            :button-text="contents[0].button.text"
            />
        
        <div class="content">

            <section class="default">
                <h2 class="title section--header">SL0W University Info, Offers & Ressources</h2>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate in sit deleniti, autem minima, saepe fugit dignissimos amet optio ipsa doloribus repudiandae qui quidem aperiam assumenda nesciunt eius, nulla animi.
                </p>
            </section>

            <section class="default gap">
                <img :src="require(`@/assets/background/dock.webp`)" alt="">
            </section>

            <section class="default infogrid">
                <div class="grid__title title">Grid Title</div>
                <div class="grid__item">Item 1</div>
                <div class="grid__item">Item 2</div>
                <div class="grid__item">Item 3</div>
                <div class="grid__item">Item 4</div>
                <div class="grid__item">Item 5</div>
                <div class="grid__item">Item 6</div>
            </section>

            <section class="default gap" />

            <section class="side-media reverse">
                <div class="media__text">
                    <div class="media--title title">Media Title</div>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis ea vero tempore tempora ipsum aliquid fuga corrupti. Ipsam minima impedit tempora laboriosam voluptatum. Blanditiis velit esse error laudantium dolores ut?
                    </p>
                </div>
                <div class="media__image">
                    <img :src="require(`@/assets/background/fleet.webp`)" alt="">
                </div>
            </section>

            <section class="side-media">
                <div class="media__text">
                    <div class="media--title title">Media Title</div>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis ea vero tempore tempora ipsum aliquid fuga corrupti. Ipsam minima impedit tempora laboriosam voluptatum. Blanditiis velit esse error laudantium dolores ut?
                    </p>
                </div>
                <div class="media__image">
                    <img :src="require(`@/assets/background/bash.webp`)" alt="">
                </div>
            </section>

            <section class="default gap" />

            <section class="default infogrid">
                <div class="grid__title title">Grid Title</div>
                <div class="grid__item">Item 1</div>
                <div class="grid__item">Item 2</div>
                <div class="grid__item">Item 3</div>
                <div class="grid__item">Item 4</div>
                <div class="grid__item">Item 5</div>
                <div class="grid__item">Item 6</div>
            </section>

            <section class="default gap" />

            <section class="default" />

            <TheFooterSection />
            
        </div>

    </div>
</template>

<script>
import TheHeroSection from '@/components/TheHeroSection.vue';
import TheFooterSection from '@/components/TheFooterSection.vue';

export default {
    props: {
        contents: Array
    },
    components: { 
        TheHeroSection, TheFooterSection
    },
    computed: {

    }
}
</script>

<style lang="scss">


</style>