<template>
    <div class="screen">
        <div class="striped" />
        <div class="center">
            <img class="image" src="https://images.evetech.net/alliances/99010735/logo" alt="loading image animation">
            <p class="text">
                Loading <span class="dots"> . . .</span>
            </p>
        </div>
        <div class="striped" />
    </div>
</template>

<style lang="scss">
@keyframes pulse {
    0% {transform: scale(1);}
    100% {transform: scale(.9);}
}
@keyframes marquee {
    0% {background-position-x: -5rem;}
    100% {background-position-x: 0;}
}
@keyframes loadingdots {
    0% {max-width: 1ch;}
    100% {max-width: 3ch;}
}
.screen {
    --_slowyellow: var(--color_logo);
    --_stripeangle: -45deg;

    position: absolute; inset: 0;
    overflow: hidden;
    z-index: 100;
    width: 100%; height: 100%;
    background: var(--content_bold);
    
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    .center {
        .image {
            height: 50vh; width: auto;
            animation: pulse 1800ms infinite alternate ease;
            user-select: none;
        }
        .text {
            text-align: start;
            font-size: large;
            width: 6rem;
            margin-block: 1rem;
            margin-inline: auto;
        }
        .dots {
            display: inline-block;
            max-height: 1rem;
            max-width: 1ch;
            overflow: hidden;
            animation: loadingdots 2000ms infinite forwards steps(3);
        }
    }

    .striped {
        height: 10vh;
        width: calc(100% + 5rem);
        background: repeating-linear-gradient(
                    var(--_stripeangle),
                    var(--_slowyellow), var(--_slowyellow) 1.5rem,
                    transparent 1.5rem,
                    transparent 3.5rem,
                    var(--_slowyellow) 3rem
                    );
        animation: marquee 1000ms linear forwards infinite;
        &:last-of-type {
            --_stripeangle: 45deg;
            animation: marquee 1000ms linear reverse infinite;
        }
    }
}
</style>