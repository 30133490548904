<template>
    <div class="entry">

        <div class="panel"
            v-for="(content, index) in contents"
            :key="index"
            @mouseenter="activePanel = index"
            @mouseleave="activePanel = -1"
            @focusout="activePanel = -1"
            @focusin="activePanel = index"
            :class="resizePanel(index)"
            >
            <div class="panel__content">
                <h2 class="title">{{ content.title }}</h2>
                <p class="blurp">{{ content.intro }}</p>
                <div class="button round">
                    <router-link :to="{ name: content.button.link }">
                    {{ content.button.text }}
                    </router-link>
                </div>
            </div>

            <div class="panel__arrow">
                <button>{{ content.arrow }}</button>
            </div>

            <div class="panel__background">
                <div class="gradient" />
                <img :src="imgSource(content.img.src)" :alt="content.img.alt">
            </div>

        </div>

    </div>
</template>

<script>

export default {
    props: {
        contents: Array
    },
    data() {
        return {
            activePanel: -1,
        }
    },
    methods: {
        resizePanel(index) {
            if (this.activePanel === -1) {
                return 'none';
            } else if (this.activePanel === index) {
                return 'active';
            } else {
                return 'inactive';
            }
        },
        imgSource: function (raw) {
            return this.$root.imgSource(raw)
        }
    }
}
</script>

<style lang="scss">

.entry {
    display: flex;
    overflow: hidden;

    --_panel-height: 100vh;
    --_panel-width: 100vw;

    .panel {
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        height: var(--_panel-height);
        width: var(--_panel-width);
        padding-inline: 1rem;
        flex-grow: 0;
        transition: width 450ms ease-in-out;

        &.inactive {
            width: calc(var(--_panel-width) / 4);
            .panel__content {display: none;}
            .panel__arrow {
                display: flex;
                transform: scale(2.5, 2);
            }
        }
        &.active {
            flex-grow: 1;
        }

        &__arrow {
            display: none;
            height: 100vh;
            position: relative;
            z-index: 15;
            justify-content: center;
            align-items: center;
            margin-inline: auto;

            transform: scale(1);
            transition: transform 350ms ease;

            button {
                all: unset; 
                color: var(--color_base);
                font-size: larger;
                font-weight: 600;
                letter-spacing: -.2rem;
                transform: scaleY(150%);
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            flex-grow: 1;
            position: relative;
            z-index: 11;
            padding-block: 20vh;
            gap: 2rem;
        }
        &__background {
            position: absolute;
            z-index: 5;
            inset: 0;
            height: inherit;
            width: 100%;
            overflow: hidden;
            isolation: isolate;
            mix-blend-mode: multiply;

            .gradient {
                position: absolute; inset: 0;
                height: inherit;
                width: 100%;
                z-index: 10;
                background-image: linear-gradient(to bottom, transparent, #091111);
                background-size: 100% 200%;
                background-position: bottom;

                transition: background-position 350ms ease-in-out;
            }
            img {
                height: 100vh; width: auto;
                position: relative; right: 0;
                z-index: 7;
            }
        }

        &:last-of-type {

            .panel__content {
            flex-direction: column-reverse;
            }
        
            .panel__background .gradient {
            background-image: linear-gradient(to top, transparent, #091111);
            background-position: top;
            }
            &:hover {
            .panel__background .gradient {
                background-position: bottom;
            }
        }
        }

        &:hover {
            .panel__background .gradient {
                background-position: top;
            }
        }
    }

    @media only screen and (width < 600px) {
        flex-direction: column;
        .panel {
            --_panel-height: 50vh;
            --_panel-width: 100vw;

            &__content {
                justify-content: center;
                padding-block: 10vh;
                gap: 1.5rem;
            }
            &.active {
                height: var(--_panel-height);
            }
            &.inactive {
                width: var(--_panel-width);
                height: var(--_panel-height);

                .panel__content {display: flex;}
                .panel__arrow {display: none;}
            }
            &:first-of-type.active {height: (--_panel-height);}
            &:first-of-type.inactive {height: var(--_panel-height);}
            &:last-of-type .panel__content {flex-direction: column;}

            .panel__content {padding-block-start: calc(10vh - 2.5rem);}
            &:first-of-type .panel__content {
                padding-block-start: calc(var(--nav-height) + 2.5rem);
            }

        }

    }

}
</style>